import React from 'react'
import styled from 'styled-components'

// Import Page Components
import AccordionComponentCustom2 from '../Shipments/ShipmentDetails/Components/AccordionComponentCustom2.js'


const GContentLocation = styled.div`
width: 100%;
.Wrap {
  margin: 0 auto;
    max-width: 1360px;
  background: transparent;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: 1fr 0.5fr 1fr 8fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "sg_default_origin sg_default_destination"
    "sg_quote_title sg_quote_title"
    "sg_quote_origin sg_quote_destination";

  .SgDefaultOrigin {
    grid-area: sg_default_origin; // 🗺
    .Title {
      font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    font-weight: 400;
    color: #2b363b;
    margin-bottom: 10px;
    }
  }
  .SgDefaultDestination {
    grid-area: sg_default_destination; // 🗺
    .Title {
      font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    font-weight: 400;
    color: #2b363b;
    margin-bottom: 10px;
    }
  }
  .SgQuoteTitle {
    grid-area: sg_quote_title; // 🗺
    font-family: "Teko","Helvetica Neue",Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    text-align: left;
    font-weight: 400;
    line-height: 1.5;
    font-size: 38px;
    color: #141b1f;
    margin-top: 20px;
  }
  .SgQuoteOrigin {
    grid-area: sg_quote_origin; // 🗺
    .Title {
      font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 18px;
      font-weight: 400;
      color: #2b363b;
      margin-bottom: 10px;
    }
    .Info {
      margin: 0;
      font-family: "Open Sans",sans-serif;
      font-size: 20px;
      font-weight: 400;
      color: #141b1f;
    }
  }
  .SgQuoteDestination {
    grid-area: sg_quote_destination; // 🗺
    .Title {
      font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 18px;
      font-weight: 400;
      color: #2b363b;
      margin-bottom: 10px;
    }
    .Info {
      margin: 0;
      font-family: "Open Sans",sans-serif;
      font-size: 20px;
      font-weight: 400;
      color: #141b1f;
    }
  }
}
`;



const EchoShipGridComponentContent = () => {
  return (

    <GContentLocation>
    <div className="Wrap">

      <div className="SgDefaultOrigin">
        <div className="Title">Default Origin </div>

        <AccordionComponentCustom2
          accordionTitle="Echo Global Logistics"
          accordionSubTitle="Business"
          accordionTitleRight="Chicago, IL"
          accordionSubTitleRight="60610"
          htmlFor="accordion-location"
        />
      </div>

      <div className="SgDefaultDestination">
        <div className="Title">Default Destination</div>
        <AccordionComponentCustom2 
          accordionTitle="Echo Global Logistics"
          accordionSubTitle="Business"
          accordionTitleRight="Long Beach, CA"
          accordionSubTitleRight="90802"
          htmlFor="accordion-location-2"
        />
      </div>

      <div className="SgQuoteTitle">Quote Defaults</div>
      <div className="SgQuoteOrigin">
      <div className="Title">Default Origin</div>
      <div className="Info">
        You don't have a default origin location. You can add one the next time you create a quote.
      </div>
      </div>
      <div className="SgQuoteDestination">
      <div className="Title">Default Destination</div>
      <div className="Info">
        You don't have a default destination location. You can add one the next time you create a quote.
      </div>
      </div>
      </div>
    </GContentLocation>

  )
}
export default EchoShipGridComponentContent;
